var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "projects"
  }, [_c('div', {
    staticClass: "projects__title"
  }, [_c('PageTitle', {
    attrs: {
      "text": "Проекты"
    }
  }), _c('div', {
    staticClass: "projects__title-options"
  }, [!_vm.currentCompanyOfUser ? _c('el-select', {
    staticClass: "hide-sm  hide-xs",
    attrs: {
      "filterable": "",
      "placeholder": "Выберите компанию"
    },
    model: {
      value: _vm.selectedCompany,
      callback: function callback($$v) {
        _vm.selectedCompany = $$v;
      },
      expression: "selectedCompany"
    }
  }, _vm._l(_vm.companiesAllList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.title,
        "value": item.id
      }
    });
  }), 1) : _vm._e(), _c('el-button', {
    attrs: {
      "to": {
        name: 'NewProject'
      },
      "type": "primary"
    },
    on: {
      "click": _vm.addNewProject
    }
  }, [_vm._v(" + Новый проект ")])], 1), !_vm.currentCompanyOfUser ? _c('el-select', {
    staticClass: "hide-lg  hide-md",
    attrs: {
      "filterable": "",
      "placeholder": "Выберите компанию"
    },
    model: {
      value: _vm.selectedCompany,
      callback: function callback($$v) {
        _vm.selectedCompany = $$v;
      },
      expression: "selectedCompany"
    }
  }, _vm._l(_vm.companiesAllList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.title,
        "value": item.id
      }
    });
  }), 1) : _vm._e()], 1), _c('ProjectsTable', {
    attrs: {
      "selected-company": _vm.selectedCompany
    }
  }), _c('el-dialog', {
    attrs: {
      "visible": _vm.visibleModal,
      "width": "400px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleModal = $event;
      }
    }
  }, [_c('div', {
    staticClass: "change-tariff"
  }, [_c('div', {
    staticClass: "change-tariff__title"
  }, [_vm._v(" Ваш тариф не поддерживает данную функцию ")]), _c('router-link', {
    staticClass: "el-button",
    attrs: {
      "to": "/tariffs"
    }
  }, [_vm._v(" Сменить тариф ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }