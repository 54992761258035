<template>
  <div class="projects">
    <div class="projects__title">
      <PageTitle text="Проекты" />
      <div class="projects__title-options">
        <el-select
          v-if="!currentCompanyOfUser"
          v-model="selectedCompany"
          filterable
          placeholder="Выберите компанию"
          class="hide-sm  hide-xs"
        >
          <el-option
            v-for="item in companiesAllList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
        <el-button
          :to="{ name: 'NewProject' }"
          type="primary"
          @click="addNewProject"
        >
          + Новый проект
        </el-button>
      </div>
      <el-select
        v-if="!currentCompanyOfUser"
        v-model="selectedCompany"
        filterable
        placeholder="Выберите компанию"
        class="hide-lg  hide-md"
      >
        <el-option
          v-for="item in companiesAllList"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        />
      </el-select>
    </div>
    <ProjectsTable :selected-company="selectedCompany" />
    <el-dialog :visible.sync="visibleModal" width="400px">
      <div class="change-tariff">
        <div class="change-tariff__title">
          Ваш тариф не поддерживает данную функцию
        </div>
        <router-link to="/tariffs" class="el-button">
          Сменить тариф
        </router-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageTitle from "../components/elements/PageTitle";
import ProjectsTable from "../components/blocks/ProjectsTable";

export default {
  name: "projects",
  components: {
    PageTitle,
    ProjectsTable
  },
  data() {
    return {
      selectedCompany: "",
      visibleModal: false
    };
  },
  computed: {
    companiesList() {
      return this.$store.state.Projects.companiesList;
    },
    pagination() {
      return this.$store.state.Projects.pagination;
    },
    companiesAllList() {
      let arr = [];
      if (this.companiesList) {
        arr = JSON.parse(JSON.stringify(this.companiesList));
        arr.unshift({ id: "all", title: "Все компании" });
      }
      return arr;
    },
    currentCompanyOfUser() {
      return this.$store.state.Auth.currentCompanyOfUser;
    },
    currentTariff() {
      return this.$store.getters["Tariffs/currentTariff"];
    },
    userRole() {
      return this.$store.state.Auth.userRole;
    }
  },
  created() {
    this.$store.dispatch("Projects/getCompaniesList").then(() => {
      if (this.$route.query.company) {
        this.selectedCompany = this.$route.query.company;
      }
    });
  },
  methods: {
    addNewProject() {
      if (
        this.pagination.totalItems >= this.currentTariff.project_count &&
        this.userRole !== "admin"
      ) {
        this.visibleModal = true;
      } else {
        this.$router.push({ name: "NewProject" });
      }
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.projects {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;

    &-options {
      display: flex;
    }

    .el-button {
      width: 20.2rem;
      margin-left: 2.4rem;
      text-decoration: none;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .projects__title {
    flex-wrap: wrap;
    .el-select {
      width: 100%;
      margin-top: 12px;
    }
    .el-input__inner {
      height: 32px;
      font-size: 12px;
      line-height: 16px;
      padding-left: 12px;
    }
    .el-input__suffix {
      right: 0;
      width: 32px;
    }
    .el-input__icon {
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
